<template>
  <div class="startSell">
    <b-container fluid>
      <a href="https://biogreenscience.com/detail-bgs-official-price" target="_blank">
          <b-row class="for_desktop promo1">
              <img src="../../assets/images/pricelists-jul-2024-h.jpg" width="100%">
              <!-- <img src="../../assets/images/join_platinum_lanscape.jpeg" width="100%"> -->
          </b-row>
          <b-row class="for_tablet promo1">
              <img src="../../assets/images/pricelists-jul-2024-s.jpg" width="100%">
              <!-- <img src="../../assets/images/join_platinum_lanscape.jpeg" width="100%"> -->
          </b-row>
          <b-row class="for_mobile promo1">
              <img src="../../assets/images/pricelists-jul-2024-v.jpg" width="100%">
              <!-- <img src="../../assets/images/join_platinum_potrait.jpeg" width="100%"> -->
          </b-row>
      </a>
        <b-row>
          <b-col>
            <div class="title"><h1>{{data.menghasilakanTitle}}</h1></div>
          </b-col>
        </b-row>
        <b-row class="videoJsPlayer for_desktop" align="center">
            <b-col>
                <video-player class="video-player-box"
                    ref="videoPlayer"
                    :options="playerOptionsDesktop"
                    :playsinline="true"
                    customEventName="customstatechangedeventname">
                </video-player>
            </b-col>
        </b-row>
        <b-row class="videoJsPlayer for_tablet" align="center">
            <b-col>
                <video-player class="video-player-box"
                    ref="videoPlayer"
                    :options="playerOptionsTablet"
                    :playsinline="true"
                    customEventName="customstatechangedeventname">
                </video-player>
            </b-col>
        </b-row>
        <b-row class="videoJsPlayer for_mobile" align="center">
            <b-col>
                <video-player class="video-player-box"
                    ref="videoPlayer"
                    :options="playerOptionsMobile"
                    :playsinline="true"
                    customEventName="customstatechangedeventname">
                </video-player>
            </b-col>
        </b-row>
        <b-row >
            <b-col class="mulaiJualanButton for_desktop">
                <a v-bind:href="'https://www.biogreensciencelogin.com/publicJoinUs.meta?intrId='+ username +'&memberPackageId=PACKAGE_PLATINUM'"><img v-bind:src="'https://api.bisnisonlinebgs.com/uploads/images/icon/' + data.imageMulaiJulan"  width="50%" alt="Mulai Berjualan Bisnisonline BGS" desc="Mulai Berjualan Bisnisonline BGS"></a>
            </b-col>
            <b-col class="mulaiJualanButton for_tablet">
                <a v-bind:href="'https://www.biogreensciencelogin.com/publicJoinUs.meta?intrId='+ username +'&memberPackageId=PACKAGE_PLATINUM'"><img v-bind:src="'https://api.bisnisonlinebgs.com/uploads/images/icon/' + data.imageMulaiJulan" width="50%" alt="Mulai Berjualan Bisnisonline BGS" desc="Mulai Berjualan Bisnisonline BGS"></a>
            </b-col>
            <b-col class="mulaiJualanButton for_mobile">
                <a v-bind:href="'https://www.biogreensciencelogin.com/publicJoinUs.meta?intrId='+ username +'&memberPackageId=PACKAGE_PLATINUM'"><img v-bind:src="'https://api.bisnisonlinebgs.com/uploads/images/icon/' + data.imageMulaiJulan" width="80%" alt="Mulai Berjualan Bisnisonline BGS" desc="Mulai Berjualan Bisnisonline BGS"></a>
            </b-col>
        </b-row>
    </b-container>
  </div>
</template>

<script>
  import axios from 'axios'
  export default {
    data() {
      return {
        bahasa:'id',
        data:{
          menghasilakanTitle:null,
          imageMulaiJulan:"mulaiJualan_id.png"
        }, 
        username:null,
        noTelp:'6281298339718',
        playerOptionsDesktop: {
          // videojs options
        //   muted: true,
          language: 'en',
          playbackRates: [0.7, 1.0, 1.5, 2.0],
          width: '800px',
          height: '450px',
          sources: [{
            type: "video/mp4",
            src: "https://api.bisnisonlinebgs.com/video/asset-bisnisonlinebgs/marketing-plan-2023.mp4"
          }],
          poster: "https://api.bisnisonlinebgs.com/video/asset-bisnisonlinebgs/MARKETPLAN2020.png",
        },
        playerOptionsTablet: {
          // videojs options
        //   muted: true,
          language: 'en',
          playbackRates: [0.7, 1.0, 1.5, 2.0],
          width: '700px',
          height: '420px',
          sources: [{
            type: "video/mp4",
            src: "https://api.bisnisonlinebgs.com/video/asset-bisnisonlinebgs/marketing-plan-2023.mp4"
          }],
          poster: "https://api.bisnisonlinebgs.com/video/asset-bisnisonlinebgs/MARKETPLAN2020.png",
        },
        playerOptionsMobile: {
          // videojs options
        //   muted: true,
          language: 'en',
          playbackRates: [0.7, 1.0, 1.5, 2.0],
          width: '290px',
          height: '170px',
          sources: [{
            type: "video/mp4",
            src: "https://api.bisnisonlinebgs.com/video/asset-bisnisonlinebgs/marketing-plan-2023-mob.mp4"
          }],
          poster: "https://api.bisnisonlinebgs.com/video/asset-bisnisonlinebgs/MARKETPLAN2020.png",
        }
      }
    },
    mounted() {
      axios.get(`https://api.bisnisonlinebgs.com/api/content/member/getMemberById${window.location.pathname}`).then(response => {
        this.noTelp = response.data.data[0].mobile_no
      })

      this.username = window.location.pathname.replace("/", "");
      if(this.username == ''){
        this.username = 'happy'
      }
      this.bahasa = localStorage.getItem('bahasa')
      this.data.imageMulaiJulan = `mulaiJualan_${this.bahasa}.png`

      axios.get(`../../lang_${this.bahasa}.json`).then(response => {
        // console.log(response.data.data[0].general[0].howToIncomeTitle)
        this.data.menghasilakanTitle = response.data.data[0].general[0].howToIncomeTitle
      })


    },
    computed: {
      player() {
        return this.$refs.videoPlayer.player
      }
    },
    methods: {
      
    }
  }
</script>

